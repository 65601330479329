.loader-spinner-enter {
	opacity: 0;
}

.loader-spinner-enter-active {
	opacity: 1;
}

.loader-spinner-exit {
	opacity: 1;
}

.loader-spinner-exit-active {
	opacity: 0;
}
