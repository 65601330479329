.rdrDateRangePickerWrapper {
    font-family: "Geomanist", "Helvetica", "Arial", sans-serif;
    display: flex;
​
    .rdrStaticRangeLabel {
        font-family: "Geomanist", "Helvetica", "Arial", sans-serif;
        line-height: 25px;
        font-size: 14px;
    }
    .rdrDayToday .rdrDayNumber span:after {
        background-color: lightgrey;
    }
    .datePicker {
        justify-content: space-around;
    }
    .rdrStaticRange{
        border: none;
    }
    .rdrCalendarWrapper, .rdrDefinedRangesWrapper {
        flex: 1;
        border: none;
    }
    .rdrDateDisplayItem input {
        color: black;

    }
    .rdrDateDisplayItemActive input{
        color: black
    }
    .rdrDateDisplayWrapper {
        background-color: rgba(74, 79, 224, 0.09);
    }
    .rdrNextButton{
        padding-left: 2px;
    }
    .rdrNextPrevButton, .rdrNextButton{
        background: rgba(74, 79, 224, 0.09);
        height: 28px;
        width: 28px;
    }
    .rdrYearPicker select{
        width: 106px
    }
    .rdrMonthPicker, .rdrYearPicker {
        border-radius: 4px;
        background-color: rgb(255, 255, 255);
        box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
        border: 1px solid transparent;
        border: 1px solid rgba(0, 0, 0, 0.36);
        & select:hover{
            background-color: #FFFFFF;
        }
    }
    .rdrMonthName{
        color: #707070;
    }

    .rdrWeekDay{
        color: #707070;

    }
    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
        color: white;
    }

    .rdrDefinedRangesWrapper {
        width: 210px;
        padding-right: 16px;
    }
}